<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Detail Persediaan Bahan'">
          <template v-slot:body>
            <div class="row align-items-center">

              <!-- Profile Image -->
              <div class="col-md-4">
                <div class="d-flex justify-content-center align-items-center image-container">
                  <div style='height: 350px; width: 350px; position: relative'>
                    <img
                      class="image"
                      :src="data.photo"
                      @load="onImageLoad()"
                      style="height: 100%;width: 100%;object-fit: contain;"
                    >
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div
                class="col"
                v-if="imgLoaded"
              >
                <table class="table mb-3">
                  <tr>
                    <td style="width: 25%"><strong>Nama</strong></td>
                    <td>{{ data.name }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Kategori</strong></td>
                    <td>{{ data.material_category_name }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Satuan</strong></td>
                    <td>{{ data.uom_name }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Keterangan</strong></td>
                    <td>{{ data.description }}</td>
                  </tr>
                </table>

                <button
                  @click="$router.push({path: '/materials/edit/' + data.id})"
                  class="btn mx-1 btn-success"
                  v-if="manipulateBtn == true"
                ><i class="far fa-fw fa-edit"></i> Edit</button>
                <button
                  @click="deleteData"
                  class="btn mx-1 btn-danger"
                  v-if="manipulateBtn == true"
                ><i class="far fa-fw fa-trash-alt"></i> Hapus</button>
              </div>

            </div>
            <div class="d-flex justify-content-start mt-8">
                <button
                  @click="$router.push('/materials')"
                  class="btn mx-1 btn-secondary"
                ><i class="fas fa-fw fa-arrow-left"></i> Kembali</button>
            </div>
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  data() {
    return {
      // Data
      data: {},
      // Other
      imgLoaded: true,
      manipulateBtn:true
    }
  },

  components: {
    Card,
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

    async deleteData() {
      let result = await module.delete('materials/' + this.$route.params.id)
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push('/materials/list')
      }
    },

    async get() {
      this.data = await module.get('materials/' + this.$route.params.id)
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/materials/list')
      }
    },
     // access management
    async setActiveMenu(){

     let access_right_user = window.localStorage.getItem("access_right_display")
     let access_right = JSON.parse(access_right_user)
    
    let a
    for(a = 0; a < access_right.length; a++){
      console.log("looping")
      
      if(access_right[a] == "1107"){
        this.manipulateBtn = true
      }
      
    }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daftar Persediaan Bahan", route: "/materials/list" },
      { title: "Detail" },
    ])
    // Get Data
    this.get()
    this.setActiveMenu()
  },
}

</script>